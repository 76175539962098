import { useState } from "react";
import InputCR from "../components/InputCR";
import ButtonCR from "../components/ButtonCR";
import './styles/passwordReset.css';
import useAuthService from "../services/useAuth.service";
import { useInput } from "../custom/useInput";


const PasswordReset = () => {

    const [correo, setCorreo] = useInput();
    const [codigo, setCodigo] = useInput();
    const [nuevaContraseña, setNuevaContraseña] = useInput();
    const [confirmarPass, setConfirmarPass] = useInput();
    const [user, setUser] = useState('');
    const [tokenTemp, setTokenTemp] = useState('');

    const { forgotPass } = useAuthService();
    const { forgotPassCheck } = useAuthService();
    const { forgotPassChange } = useAuthService();

    const sendEmail = () => {
        const validarCorreo = () => {
            let expReg = /^[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'+/=?^_`{|}~-]+)@(?:[a-z0-9](?:[a-z0-9-][a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
            let esValido = expReg.test(correo.value)
            if (esValido === true) {

            }

        };
        validarCorreo();

        if (correo.value.length < 5 || correo.value.length > 59) {
            return;
        }
        forgotPass(correo.value)
            .then(nameUser => setUser(user))

    };

    const sendCode = () => {
        if (user === undefined) {
            return;
        }
        if (codigo.value.length !== 5) {
            return;
        }
        forgotPassCheck(user, codigo.value)
            .then(temTkn => setTokenTemp(tokenTemp))

    };

    const sendNewPassword = () => {
        if (tokenTemp === undefined) {
            return;
        }
        if (nuevaContraseña.value.length < 5 || nuevaContraseña.value.length > 29) {
            return;
        } if (nuevaContraseña !== confirmarPass) {
            return;
        }
        forgotPassChange(tokenTemp, nuevaContraseña.value);
    };

    return (
        <div className="password-reset-box">
            <div className="tittle-box">
                <h1>GanadApp</h1>
                <h3>Recuperación de contraseña</h3>
            </div>
            <div className="correo-box">
                <InputCR
                    value={correo.value}
                    onChange={setCorreo}
                    placeholder="Correo electronico"
                />

                <ButtonCR
                    title="Enviar"
                    onClick={sendEmail}
                />
            </div>
            <div className="codigo-box">
                <h5>Ingresar codigo</h5>
                <InputCR
                    value={codigo.value}
                    onChange={setCodigo}
                    placeholder="xxxxxxx"
                />

                <ButtonCR
                    title="Enviar"
                    onClick={sendCode}
                />
            </div>
            <div className="new-password-box">
                <h5>Ingresar nueva contraseña</h5>
                <InputCR
                    value={nuevaContraseña.value}
                    onChange={setNuevaContraseña}
                    placeholder="Nueva contraseña"
                />

                <InputCR
                    value={confirmarPass.value}
                    onChange={setConfirmarPass}
                    placeholder="Confirmar contraseña"
                />

                <ButtonCR
                    title="Confirmar"
                    onClick={sendNewPassword}
                />
            </div>
        </div>
    )
};
export default PasswordReset;

