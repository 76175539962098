import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/Auth.context';

export const PreventLogin = () => {

    const { token } = useAuth();

    if (token) {
        return <Navigate to="/" />
    }
    return <Outlet />
}