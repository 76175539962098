import { InputApp, InputAppProps } from "appdland-ui"


interface InputCRInt extends Omit<InputAppProps, "type"> {
    placeholder?: string;
    type?: 'text' | 'number' | 'money' | 'password';

}

const InputCR = ({ placeholder = 'Registrar datos', type = 'text', ...props }: InputCRInt) => {

    return (
        <InputApp
            type={type}
            placeholder={placeholder}
            {...props}
            style={{
                background: "transparent",
                color: "#CBDED6",
                blurColor: '#378064',
                placeholderColor: '#B3E0CB',
                blurPlaceholderColor: '#378064',
                textAlign: "center",
                ...props.style
            }}
            errorBelowInput
        />
    )
}
export default InputCR;