import axios from 'axios';
import React, { useState, createContext, useEffect, useContext } from 'react';

interface AuthContextInt {
    token: string | undefined | null;
    setToken: (token: string | undefined | null) => void;
}

const AuthContext = createContext<AuthContextInt | null>(null);

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | undefined | null>();

    const clearData = () => {
        for (let index = 0; index < localStorage.length; index++) {
            const key = localStorage.key(index);
            if (key && key.startsWith('_ganadapp')) {
                localStorage.removeItem(key);
                index--;// Decrementar el índice porque al eliminar un elemento, el length de localStorage disminuye.
            }
        }
    }

    useEffect(() => {
        const storage = localStorage.getItem("__auth__ganadapp__");
        if (storage) {
            axios.get(process.env.REACT_APP_LOGIN_HEALTH || "", { headers: { "auth-token": storage } })
            .then(({ data }) => {
                    if (data.reload === true) {
                        clearData();
                    }
                    setToken(storage);
                })
                .catch((error: any) => {
                    console.log(error.response.data);
                    setToken(null);
                })
        } else {
            setToken(null);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("__auth__ganadapp__") && token) {
            localStorage.setItem('__auth__ganadapp__', token);
        }
        //vamos a evaluar null, esta linea se espera para un proceso de logout o un token vencido
        if (token === null) {
            localStorage.removeItem("__auth__ganadapp__");
            clearData();
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextInt => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext debe ser utilizado dentro de AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };