
interface TitleCRInt {
    children: React.ReactNode;
}

const TitleCR = (props: TitleCRInt) => {

    return (
        <h2
            style={{
                color: "#B3E0CB",
                fontSize: "large",
                textAlign: "center",
                fontWeight: "normal",
                userSelect: "none"
            }}
        >
            {props.children}
        </h2>
    )
}

export default TitleCR;