import { ButtonApp } from 'appdland-ui';
import { ButtonAppProps } from 'appdland-ui/dist/components/button/ButtonApp.types';

interface ButtonCRInt extends Omit<ButtonAppProps, "buttonStyle"> {
}

const ButtonCR = ({ ...props }: ButtonCRInt) => {


    return (
        <ButtonApp
            style={{
                backgroundColor: '#378064',
                textColor: "#B3E0CB"
            }}
            {...props}
        />
    )
}
export default ButtonCR;
