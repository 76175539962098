import './styles/loading.css';

export const Loading = () => {

    return (
        <div className='loading-screen'>
            <div className="loading-circle">
                <div className="lds-roller">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    );
};
